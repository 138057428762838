import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ControlPointSharpIcon from "@mui/icons-material/ControlPointSharp";
import HistorySharpIcon from "@mui/icons-material/HistorySharp";
import StackedLineChartSharpIcon from "@mui/icons-material/StackedLineChartSharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import AddPage from "./components/AddPage"; // Import your page components
import HistoryPage from "./components/HistoryPage";
import StatsPage from "./components/StatsPage";
import SettingsPage from "./components/SettingsPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

export default function App() {
  const [value, setValue] = React.useState("recents");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#20B2AA", // light teal color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppBar position="static" style={{ backgroundColor: "#20B2AA" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MonitorHeartIcon />
            </IconButton>
            <Typography variant="h6">Blood Pressure Monitor</Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <div style={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<AddPage />} />
              <Route path="/add" element={<AddPage />} />{" "}
              {/* Render the AddPage component when the URL is /add */}
              <Route path="/history" element={<HistoryPage />} />{" "}
              {/* Render the HistoryPage component when the URL is /history */}
              <Route path="/stats" element={<StatsPage />} />{" "}
              {/* Render the StatsPage component when the URL is /stats */}
              <Route path="/settings" element={<SettingsPage />} />{" "}
              {/* Render the SettingsPage component when the URL is /settings */}
              {/* Add more routes as needed */}
            </Routes>
          </div>

          <BottomNavigation
            color="primary"
            showLabels
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
            }}
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label="Add"
              value="add"
              icon={<ControlPointSharpIcon />}
              component={Link}
              to="/add" // Link to the AddPage
            />
            <BottomNavigationAction
              label="History"
              value="history"
              icon={<HistorySharpIcon />}
              component={Link}
              to="/history" // Link to the HistoryPage
            />
            <BottomNavigationAction
              label="Stats"
              value="stats"
              icon={<StackedLineChartSharpIcon />}
              component={Link}
              to="/stats" // Link to the StatsPage
            />
            <BottomNavigationAction
              label="Settings"
              value="settings"
              icon={<SettingsSharpIcon />} // Use the imported icon
              component={Link}
              to="/settings" // Link to the SettingsPage
            />
          </BottomNavigation>
        </div>
      </Router>
    </ThemeProvider>
  );
}
