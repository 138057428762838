import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
} from "@mui/material";
import db, { IEntry } from "../database/db";

function HistoryPage() {
  const [entries, setEntries] = useState<IEntry[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allEntries = await db.entries.toArray();
        setEntries(allEntries);
      } catch (error) {
        console.error("Failed to fetch entries:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pt={5}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Systolic</TableCell>
                <TableCell>Diastolic</TableCell>
                <TableCell>Pulse</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.systolic}</TableCell>
                  <TableCell>{entry.diastolic}</TableCell>
                  <TableCell>{entry.pulse}</TableCell>
                  <TableCell>{entry.date.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export default HistoryPage;
