// AddPage.tsx
import React, { useState } from "react";
import MultiPicker from "rmc-picker/lib/MultiPicker";
import Picker from "rmc-picker/lib/Picker";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Button from "@mui/material/Button";

import "./add-page.css"; // import your CSS file
import db from '../database/db';

const AddPage = () => {
  const [systolic, setSystolic] = useState(["120"]);
  const [diastolic, setDiastolic] = useState(["80"]);
  const [pulse, setPulse] = useState(["60"]);

  async function addEntry(systolic: number, diastolic: number, pulse: number) {
    await db.entries.add({ systolic, diastolic, pulse, date: new Date() });
  }

  const generateNumbers = (from: number, to: number) => {
    let numbers = [];
    for (let i = from; i <= to; i++) {
      numbers.push(
        <Picker.Item
          className="my-picker-view-item"
          value={i.toString()}
          key={i}
        >
          {i}
        </Picker.Item>
      );
    }
    return numbers;
  };

  const [selectedDate, setSelectedDate] = useState(dayjs());

  return (
    <Container maxWidth="md">
      {/* This Box is a flex container with its direction set to column (flexDirection="column"). This means its children will be stacked vertically. */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pt={5}
      >
        {/* The justifyContent="space-between" property is used to distribute the children evenly in the horizontal direction, */}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box textAlign="center" flexBasis="33%">
            <h2>Systolic</h2>
            <MultiPicker selectedValue={systolic} onValueChange={setSystolic}>
              <Picker indicatorClassName="my-picker-indicator">
                {generateNumbers(80, 200)}
              </Picker>
            </MultiPicker>
          </Box>
          <Box textAlign="center" flexBasis="33%">
            <h2>Diastolic</h2>
            <MultiPicker selectedValue={diastolic} onValueChange={setDiastolic}>
              <Picker indicatorClassName="my-picker-indicator">
                {generateNumbers(60, 120)}
              </Picker>
            </MultiPicker>
          </Box>
          <Box textAlign="center" flexBasis="33%">
            <h2>Pulse</h2>
            <MultiPicker selectedValue={pulse} onValueChange={setPulse}>
              <Picker indicatorClassName="my-picker-indicator">
                {generateNumbers(40, 180)}
              </Picker>
            </MultiPicker>
          </Box>
        </Box>
      </Box>
      {/* Date */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pt={5}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" justifyContent="space-between">
            <DatePicker
              format="YYYY/MM/DD"
              label="Date Picker"
              value={selectedDate} // Dayjs instance
              onChange={(date) => setSelectedDate(dayjs(date))} // convert Date to Dayjs instance
            />
            <TimePicker
              label="Time Picker"
              value={selectedDate}
              onChange={(date) => setSelectedDate(dayjs(date))}
              ampm={false} // 24-hour mode
            />
          </Box>
        </LocalizationProvider>
      </Box>
      {/* DAte */}
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "75%" }}
          onClick={() => addEntry(parseInt(systolic[0]), parseInt(diastolic[0]), parseInt(pulse[0]))}
          >
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default AddPage;
