import React, { useState } from "react";
import db from "../database/db";
import Papa from "papaparse";
import { Dialog, DialogContent, DialogActions, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

const SettingsPage = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function exportDataJson() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");

    const timestamp = `${year}${month}${day}_${hour}${minute}`;
    const filename = `blood_pressure_data_${timestamp}.json`;

    const entries = await db.entries.toArray();
    const blob = new Blob([JSON.stringify(entries)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    // TODO: Upload blob to Google Drive, Dropbox, etc.
  }

  async function exportDataCsv() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed in JavaScript
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");

    const timestamp = `${year}${month}${day}_${hour}${minute}`;
    const filename = `blood_pressure_data_${timestamp}.csv`;

    const entries = await db.entries.toArray();
    const csv = Papa.unparse(entries);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    // TODO: Upload blob to Google Drive, Dropbox, etc.
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Container maxWidth="md">
        <Typography variant="h6" align="left" gutterBottom>
          Contact
        </Typography>
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "75%" }}
            component={Link}
            href="https://docs.google.com/forms/d/e/1FAIpQLScJp34Vx4aWSqFX-C-Hdq6CfrsvEGrJyK0RqFTmPpbEsi-vpA/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us (External)
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "75%" }}
            onClick={handleOpen}
          >
            Feedback (In App)
          </Button>
          <Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  maxWidth="md" // Set maxWidth to "md", "lg", "xl", or "false" to disable max width
  fullWidth={true} // Set fullWidth to true to make the dialog take up the full width
>
  <IconButton
    edge="end"
    color="inherit"
    onClick={handleClose}
    aria-label="close"
    style={{ position: 'absolute', right: 0, top: 0 }}
  >
    <CloseIcon />
  </IconButton>
  <DialogContent>
    <iframe
      title="Feedback Form"
      src="https://docs.google.com/forms/d/e/1FAIpQLScJp34Vx4aWSqFX-C-Hdq6CfrsvEGrJyK0RqFTmPpbEsi-vpA/viewform?embedded=true"
      style={{
        border: "none",
        margin: 0,
        width: "100%",
        height: "80vh",
      }}
    >
      Loading…
    </iframe>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>
        </Box>
        <Typography variant="h6" align="left" gutterBottom>
          Export
        </Typography>
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "75%" }}
            onClick={exportDataJson}
          >
            Export Data (json)
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "75%" }}
            onClick={exportDataCsv}
          >
            Export Data (csv/xls)
          </Button>
        </Box>
      </Container>
    </Grid>
  );
};

export default SettingsPage;
