import { Dexie } from 'dexie';

export interface IEntry {
  id?: number;
  systolic: number;
  diastolic: number;
  pulse: number;
  date: Date;
}

class IMyDatabase extends Dexie {
  entries: Dexie.Table<IEntry, number>;

  constructor() {
    super("MyAppDatabase");
    this.version(1).stores({
      entries: '++id, systolic, diastolic, pulse, date'
    });

    this.entries = this.table("entries");
  }
}

const db = new IMyDatabase();
export default db;
