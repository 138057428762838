import React, { useEffect, useState } from "react";
import { Container, Box } from "@mui/material";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";
import db, { IEntry } from "../database/db";

const StatsPage = () => {
  const [entries, setEntries] = useState<IEntry[]>([]);
  // const [chartWidth, setChartWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allEntries = await db.entries.toArray();
        setEntries(allEntries);
      } catch (error) {
        console.error("Failed to fetch entries:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   // const handleResize = () => setChartWidth(window.innerWidth);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const maxSystolic = Math.max(...entries.map((entry) => entry.systolic));
  const maxDiastolic = Math.max(...entries.map((entry) => entry.diastolic));
  const maxPulse = Math.max(...entries.map((entry) => entry.pulse));
  const maxY = Math.max(maxSystolic, maxDiastolic, maxPulse);

  const ticks = [];
  for (let i = 40; i <= maxY + 20; i += 10) {
    ticks.push(i);
  }

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pt={5}
      >
        <ResponsiveContainer width="100%" height={500}>
          <LineChart width={800} height={500} data={entries}>
            <Line type="monotone" dataKey="systolic" stroke="#1565c0" />{" "}
            {/* Blue 800 */}
            <Line type="monotone" dataKey="diastolic" stroke="#2e7d32" />{" "}
            {/* Green 800 */}
            <Line type="monotone" dataKey="pulse" stroke="#c62828" />{" "}
            {/* Red 800 */}
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="date" />
            <YAxis domain={[40, "auto"]} ticks={ticks} />
            <Legend verticalAlign="bottom" height={36} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Container>
  );
};

export default StatsPage;
